<template>
  <el-dialog :visible.sync="nameShow" :title="$t('nickname.title')" center :modal="false">
    <p class="t-center">{{ $t("nickname.content") }}</p>
    <el-form class="m-t-20">
      <el-form-item>
        <el-input
          v-model="name"
          auto-complete="off"
          :placeholder="$t('nickname.tip')"
          @keyup.enter="nameFun"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button @click="nameFun" :loading="loading" class="w-100">{{
          $t("messageBox.save")
        }}</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      nameShow: false,
      loading: false,
      name: "",
      timer: null,
    };
  },
  computed: {
    ...mapState({
      infos: (state) => state.infos,
    }),
  },
  mounted() {
    this.name = this.infos.nickname;
  },
  watch: {
    nameShow(n, o) {
      if (n) {
        this.nameForm = {
          name: this.infos.nickname || "",
        };
      }
    },
    name(n, o) {
      if (n) {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          let j = 0;
          for (let i = 0; i < n.length; i++) {
            j += n[i].match(/[^\x00-\xff]/gi) != null ? 2 : 1;
            if (j > 20) {
              this.name = n.substring(0, i);
              break;
            }
          }
        }, 300);
      }
    },
  },
  methods: {
    nameFun() {
      if (!this.name) return this.$message(this.$t("nickname.p_nickname"));
      this.loading = true;
      this.$axios
        .post(
          this.$api.resetName,
          {
            nickname: this.name,
          },
          { Serverid: this.$store.state.serverid }
        )
        .then((res) => {
          this.$message.success(this.$t("nickname.ok"));
          this.loading = false;
          this.nameShow = false;
          this.$store.dispatch("Get_infos");
        })
        .catch((err) => {
          this.loading = false;
        });
    },
  },
};
</script>

<style>
</style>